import { useState } from 'react';
import './index.css';
import _ from '../../../_';
import { useGlobal } from '../../../context/global';

const Tab = ({tabs, def, render}) => {

  var [ active, setActive ] = useState(def || 0)
  const { auth } = useGlobal()

  const renderTabs = _.filterAccess(tabs, auth.user)

  return (
    <div className='tab-main'>
      
      <div className='tab-tabs'>
        {
          renderTabs.map((tab, i) =>
            <div className={`tab-tab ${active==i?'active':''}`} style={{width: `${100/(renderTabs.length)||1}%`}} key={tab.header} onClick={()=>{setActive(i)}}>
              <div className={`tab-head`}>{tab.header}</div>
            </div>
          )
        }
      </div>

      <div>
        {
          render?
            renderTabs[active].comp
          :
            <div className='tab-ch'>
              {
                renderTabs.map((tab, i) => <div style={{display: active!=i?'none':''}}>{tab.comp}</div>)
              }
            </div>
        }
      </div>

    </div>
  )
}

export default Tab;
