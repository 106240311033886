import store from "../../../../store"
import DP from "../../dp"
import Icon from "../../icon"
import { useNavigate } from "react-router-dom"
import "./index.css"
import PageLoader from "../../../info/pageloader"
import Glass from "../../glass"
import { useState } from "react"
import { useGlobal } from "../../../../context/global"
import { toast } from "react-toastify"

const UserMenu = ({ close }) => {
  const { auth } = useGlobal()
  const { user, users, index } = auth
  const [showBlockLoader, setShowBlockLoader] = useState(false)
  const navigate = useNavigate()
  const menus = [
    // {
    //     name: "Change Photo", 
    //     link: "/edit-up",
    //     icon: "account-circle",
    //     access: 9
    // }
  ]

  const logout = () => {
    if (users.length == 1) {
      store.clear().then(() => {
        navigate("/login")
      }).catch(error => {
        toast.error(error)
      })
    } else {
      const newUsers = users.filter((u, i) => {
        return i != index
      })

      store.set({
        index: 0,
        users: newUsers
      }).then(() => {
        setShowBlockLoader(true)
        window.open("/home", "_self")
      }).catch(error => {
        toast.error(error)
      })


    }
  }

  const handleSwitchAccount = (usr) => {
    var i = 0
    for (let k = 0; k < users.length; k++) {
      if (users[k].id == usr.id && users[k].roleID == usr.roleID) {
        i = k
        break
      }
    }
    store.set({
      index: i,
      users
    }).then(() => {
      setShowBlockLoader(true)
      window.open("/home", "_self")
    }).catch(error => {
      toast.error(error)
    })

  }

  const handlePassiveUserLogout = (usr) => {
    const newUsers = users.filter(u => {
      return !(u.id == usr.id && u.roleID == usr.roleID)
    })

    store.set({
      index: 0,
      users: newUsers
    }).then(() => {
      setShowBlockLoader(true)
      window.open("/home", "_self")
    }).catch(error => {
      toast.error(error)
    })
  }

  const getBadge = (u) => {

    var badge = {
      color: 'white',
      icon: {
        color: '#555'
      }
    }

    switch (u.roleID) {
      case 'app-admin':
        badge.icon.name = 'settings'
        return badge
      case 'folk-guide':
        badge.icon.name = 'supervisor-account'
        return badge
      case 'buddy-coord':
        badge.icon.name = 'group'
        return badge
      default:
        return null
    }
  }

  const editPhoto = () => {
    navigate('/edit-up')
  }

  return (
    <>
      <Glass onClick={close} delay={.2} />
      <div className={`header-user-menu`}>

        {showBlockLoader ? <PageLoader text={"Loading..."} block /> : null}

        <div className="usermenu-header">
          <div className="usermenu-label">USER MENU</div>
          <Icon name="close" color="#fff" size={[5, 2.5, 1.75]} className="header-user-menu-close" onClick={close} />
        </div>

        <div className='header-user-profile-header'>
          <DP user={user} className="user-profle-dp" size={[17.5, 9, 6.5]} enlarge badge={{
            size: [7.5, 3.25, 3],
            icon: {
              name: 'edit',
              onClick: editPhoto
            }
          }} />
          <div className='header-user-details'>
            <div className='header-username'>{user.name}</div>
            <div className='header-userrole'>{user.roleName}</div>
          </div>
        </div>

        <div className='header-actions'>
          <div>

            {menus.length ?
              <>
                <hr className='header-user-hr' />
                <div>
                  <div className='header-action-label'>ACTIONS</div>
                  <div className='header-action-list'>
                    {
                      menus.filter(m => {
                        return m.access >= 0
                      }).map(m => {
                        const handleMenuClick = () => {
                          if (m.link) {
                            navigate(m.link)
                          } else {
                            m.onClick()
                          }
                        }
                        return (
                          <div className='header-action'>
                            <Icon name={m.icon} color="#fff" />
                            <div className='usermenu-action-item' onClick={handleMenuClick}>{m.name}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </> : null
            }

            {user && users.length > 1 ?
              <>
                <hr className='header-user-hr' />

                <div className='header-accounts'>
                  <div className='header-action-label'>SWITCH ACCOUNTS</div>
                  <div className='header-action-list'>
                    {
                      users.filter(u => {
                        return u.id != user.id || u.roleID != user.roleID
                      }).map(u => {
                        return <div className='usermenu-switch-action'>
                          <div className="usermenu-switch-details">
                            <DP user={u} size={12.5} className="usermenu-switch-dp" badge={getBadge(u)} enlarge />
                            <div className='usermenu-action-item' onClick={() => { handleSwitchAccount(u) }}>
                              <div className="usermenu-switch-name">{u.name}</div>
                              <div className='usermenu-switch-role'>{u.roleName}</div>
                            </div>
                          </div>
                          <Icon onClick={() => { handlePassiveUserLogout(u) }} size={5} className="usermenu-switch-logout" name="logout" color="white" />
                        </div>
                      })
                    }
                  </div>

                </div>
              </> : null
            }
          </div>
          <div>
            <hr className='header-user-hr' />
            <div className='usermenu-logout'>
              <Icon name={"logout"} color="#fff" size={6} />
              <div onClick={logout}>{"Log Out"}</div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default UserMenu