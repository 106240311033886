import { useCallback, useEffect, useRef, useState } from "react"
import "./index.css"
import Field from "./field"
import Icon from "../icon"
import { toast } from 'react-toastify'
import { getPersistedPage, getPersistedValues, persistData, getPageValidity } from "./util"

const useForm = (name, persist, updateMode, config, def) => {
    const formName = `form-${name}`

    const [values, setValues] = useState(def || getPersistedValues(formName, persist))
    var [ page, setPage ] = useState(getPersistedPage(formName, persist))
    var [ pageValidity, setPageValidity ] = useState()
    var [ changes, setChanges ] = useState({})

    const updateValues = (name, value) => {
        setValues({ ...values, [name]: value })

        if(updateMode){
            setChanges({ ...changes, [name]: value })
        }
    }

    useEffect(()=>{
        persistData(formName, values, page)
        setPageValidity(getPageValidity(config[page], values))
    }, [page, values])

    const resetForm = ()=>{
        localStorage.clear(formName)
        window.location.reload()
    }

    const next = useCallback(()=>{
        if(pageValidity){
            setPage(page => page===config.length-1?page:page+1)
        }else{
            toast.error("Some fields are invalid! Please check..")
        }
        return pageValidity
    }, [page, pageValidity])

    const back = useCallback(()=>{
        setPage(page => page==0?config.length-1:page-1)
    }, [page, pageValidity])

    return { values, changes, page, pageValidity, next, back, updateValues, resetForm }
}

const Form = props => {

    const { name, config, onFieldUpdate, onSubmit, paginate, persist, user, def, viewOnly, skip, disableReset, updateMode, submitText, only, noheaders, nomand, floatingSubmit} = props
    const { values, changes, page, pageValidity, next, back, updateValues, resetForm } = useForm(name, persist, updateMode, config, def)

    const handleFieldUpdate = useCallback((name, value) => {
        updateValues(name, value)
    })

    const handleSubmit = useCallback(() => {
        if(pageValidity){
            if(updateMode && Object.keys(changes).length==0){
                toast.warn("No changes to save..")
            }else{
                onSubmit && onSubmit(values, changes, resetForm)
            }
        }else{
            toast.error("Some fields are invalid! Please check..")
        }
    }, [values, pageValidity, changes])

    useEffect(()=>{
        onFieldUpdate && onFieldUpdate(values)
    }, [values])

    const ref = useRef()
    useEffect(()=>{
        page && ref.current.scrollIntoView({ behavior: 'smooth' })
    }, [page])

    const handleResetField = ()=>{
        if(window.confirm('Are you sure you want to reset all the fields?')){
            resetForm()
        }
    }

    return (
        <div className="form-main" ref={ref}>

            {!viewOnly && <div className="form-head">
                {!disableReset && <div className="form-cdef" onClick={handleResetField}>{'Reset Form'}</div>}
                {!nomand && <div className="form-mand">{'* indicates mandatory field'}</div>}
            </div>}

            <div style={{width: '100%'}}>
                {
                    config.filter(c=> only?c.fields.map(c=>c.name).some(name => only.includes(name)):true).map((c, i) => 
                        <div className={`form-section ${viewOnly?'view-only':''} ${paginate && page!=i?'hide':''}`} key={`form-section-${i}`}>
                            {!noheaders && <div className={`form-section-label ${viewOnly?'view-only':''}`}>{(c.section[user] || c.section).toUpperCase()}</div>}
                            {!viewOnly && !noheaders && <hr/>}
                            <div className={`form-field-group ${viewOnly?'view-only':''}`}>
                                {
                                    c.fields
                                        .filter(c=>only?only.indexOf(c.name)!=-1:true)
                                        .filter(c=>skip?skip.indexOf(c.name)==-1:true)
                                        .filter(c=>c.condition?c.condition(values):true)
                                        .map(c=>{
                                        return (
                                            <div className={`form-item ${viewOnly?'view-only':''}`}>
                                                <Field
                                                    config={c}
                                                    onEditComplete={handleFieldUpdate}
                                                    values={values}
                                                    def={viewOnly?(c.pre?c.pre(values[c.name]):values[c.name]):values[c.name]}
                                                    user={user}
                                                    viewOnly={viewOnly}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div>

            {paginate && <div className="form-paginator">

                <div className="form-page">{`Page ${page+1} of ${config.length}`}</div>

                <div className="form-indicators">
                    {
                        config.map((c, i)=> <div className={`form-indicator ${i<page?'over':''}`}/>)
                    }
                </div>

                <div className="form-navigators">
                    {page!=0 && <button tabIndex={0} className="form-navigator" onClick={back}>Back</button>}
                    {page!=config.length-1 && <button tabIndex={0} className={`form-navigator`} onClick={next}>Next</button>}
                    {page==config.length-1 && <button tabIndex={0} className={`form-navigator`} onClick={handleSubmit}>{submitText || (updateMode?'Save Changes':'Submit')}</button>}
                </div>
            </div>}
            {(!paginate && !viewOnly) && (!floatingSubmit?
                <button tabIndex={0} className={`form-navigator`} onClick={handleSubmit}>{submitText || (updateMode?'Save Changes':'Submit')}</button>:
                <div className="form-float" onClick={handleSubmit}><Icon name='save' color='#fff'/></div>)
            }
        </div>
    )
}

export default Form