import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "./index.css"
import _ from "../../_"
import store from "../../store"
import Spinner from "../../components/info/spinner"
import { useGlobal } from '../../context/global'
import DP from '../../components/custom/dp'

const Login = () => {

  const { api } = useGlobal()

  const InitState = 0
  const UserVerificationPending = 1
  const OTPSendingPending = 2
  const OTPSendingSuccessful = 3
  const OTPVerificationPending = 4
  const SignInPending = 5

  const initUserPrompt = "Enter your registered WhatsApp number or Email ID"
  const expirySeconds = 300
  const resendWaitSeconds = 100

  var [ loginState, setLoginState ] = useState(InitState)
  var [ statusText, setStatusText ] = useState("")
  var [ resendOTP, setResendOTP ] = useState("")
  var [ showResendOTP, setShowResendOTP ] = useState(false)
  var [ buttonText, setButtonText ] = useState("Send OTP")
  var [ promptText, setPromptText ] = useState(initUserPrompt)
  var [ inputType, setInputType ] = useState("text")
  var [ inputMaxLen, setInputMaxLen ] = useState(-1)
  var [ timerOn, setTimerOn ] = useState(false)

  var id = useRef()
  var target = useRef()
  var timerID = useRef()
  var expiry = useRef()
  var users = useRef()

  const handleSendOTP = ()=>{
    if(loginState==OTPSendingPending){
      return
    }
    setLoginState(UserVerificationPending)
    api.call("/verify-user", { id: id.current.value.trim().toPhoneCase() })
    .then(res=>{
      users.current = res.data
      setLoginState(OTPSendingPending)
    }).catch(err=>{
      setLoginState(InitState)
      if(err && err.response && err.response.status==404){
        toast.error("This user is not found in our records!")
      }else{
        console.log(err)
        toast.error(`User could not be verified!`)
      }
    })
  }

  const handleVerifyOTP = ()=>{

    if(loginState==UserVerificationPending){
      return
    }
    setLoginState(OTPVerificationPending)
    api.call("/verify-otp", { 
      id: `sevabase-login-${target.current}`,
      otp: id.current.value.trim()
    }).then(()=>{
      setLoginState(SignInPending)
      store.set({
        index: 0,
        users: users.current
      }).then(() => {
        clearInterval(timerID.current)
        window.open(new URL(window.location.href).searchParams.get("redirect") || "/home", "_self")
      }).catch(err => {
        toast.error(err)
      })

    }).catch(err=>{
      setLoginState(OTPSendingSuccessful)
      if(err.response.status==403){
        id.current.value=""
        toast.error(`Incorrect OTP. Please enter again.`)
      }else if(err.response.status==408){
        id.current.value=""
        toast.error(`Request timed-out. Please try again`)
      }else{
        toast.error(`Could not verify OTP: ${err.response.data}. Please try again!`)
      }
    })
  }

  useEffect(()=>{
    if(loginState == InitState){
      setStatusText("")
      setButtonText("Send OTP")
      setPromptText(initUserPrompt)
      setInputType("text")
      setInputMaxLen(-1)
    } else if(loginState == UserVerificationPending){
      setStatusText("Verifying user..")
      setButtonText("Verifying user..")
    } else if(loginState == OTPSendingPending){
      setStatusText("Sending OTP. This may take a couple of minutes...")
      setButtonText("Sending OTP...")
    } else if(loginState == OTPSendingSuccessful){
      setButtonText("Verify OTP")
      setPromptText(`Enter the 6-digit OTP sent over ${_.phoneRegex.test(target.current)?"WhatsApp":"e-mail"} to ${target.current}`)
      setTimerOn(true)
      setInputType("number")
      setInputMaxLen(6)
      id.current.focus()
      if(!expiry.current){
        expiry.current = moment().add(expirySeconds, 'seconds')
      }
    } else if (loginState == OTPVerificationPending){
      setButtonText("Verifying OTP...")
    } else if (loginState == SignInPending) {
      setStatusText("Signing you in..")
      setTimerOn(false)
    } else {
      setStatusText("")
    }
  }, [loginState])

  useEffect(()=>{
    if(loginState == OTPSendingPending){
      clearInterval(timerID.current)
      const value = (id.current.value || target.current).trim().toPhoneCase()
      api.call("/send-otp", { 
        id: `sevabase-login-${value}`,
        target: value
      }).then(()=>{
        if(!target.current){
          target.current = id.current.value.trim().toPhoneCase()
        }
        setLoginState(OTPSendingSuccessful)
        setShowResendOTP(false)
        id.current.value = ""
      }).catch(err=>{
        console.log(err)
        setLoginState(InitState)
        toast.error(`Could not send OTP: ${err.response.data}. Please try again!`)
      })
    }
  }, [loginState])

  useEffect(()=>{
    if(timerOn){
      setStatusText(`OTP will expire in 05:00`)
      timerID.current = setInterval(()=>{
        const secondsRemaining = moment.utc(expiry.current.diff(moment()))/1000
        if(secondsRemaining <= 1){
          setTimerOn(false)
          setLoginState(InitState)
          toast.error("OTP expired! Please try again.")
          return
        }
        if(Math.floor(secondsRemaining) == expirySeconds-resendWaitSeconds){
          setShowResendOTP(true)
        }
        setStatusText(`OTP will expire in ${moment.utc(expiry.current.diff(moment())).format('mm:ss')}`)
        if(!showResendOTP){
          setResendOTP(`You can request to resend OTP after ${moment.utc(moment(expiry.current).subtract(expirySeconds-resendWaitSeconds, 'seconds').diff(moment())).format('mm:ss')}`)
        }
      }, 1000)
    }else{
      clearInterval(timerID.current)
    }
  }, [timerOn, showResendOTP])

  const handleSubmit = (e)=>{
    if(e.code=="Enter"){
      if(loginState == OTPSendingSuccessful){
        handleVerifyOTP()
      }else{
        handleSendOTP()
      }
    }
  }

  const handleResendOTP = ()=>{
    expiry.current = moment().add(expirySeconds, 'seconds')
    setLoginState(OTPSendingPending)
  }

  return (
    <div className="login-container">

        <div className='login-logo-cont'>
          <DP url="/favicon.png" size={50}/>
        </div>

        <div className='login-status-cont'>
          <div className='login-status'>{statusText}</div>
          {loginState==SignInPending?<Spinner size={5}/>:null}
        </div>

        {loginState<SignInPending?
          <div className='login-form'>
            <label className='login-label'>{promptText}</label>
            <input
              className="login-input"
              ref={id}
              type={inputType}
              maxLength={inputMaxLen}
              onKeyDown={handleSubmit}
            />

            <button
              className="login-send-button"
              onClick={loginState == OTPSendingSuccessful? handleVerifyOTP: handleSendOTP}
              disabled={loginState==OTPSendingPending || loginState == OTPVerificationPending}
            >
              {buttonText}
            </button>

            {loginState==OTPSendingSuccessful && 
              <div className='login-buttons'>
                {!showResendOTP && <div>{resendOTP}</div>}
                {showResendOTP && <button className='login-send-button login-resend-button' onClick={handleResendOTP}>Resend OTP</button>}
              </div>
            }
          </div>:null
        }

    <div className='login-register'>
          <div className='login-reg-prompt'>Don't have an account yet?</div>
          <a className='login-reg-link' href='/register' target='_self'><button className='login-reg-button'>Register Now</button></a>
        </div>
    </div>
  )
}

export default Login