import "./index.css"

const UserAgent = () => {

    const UAParser = require('ua-parser-js')
    const info = JSON.stringify(new UAParser().setUA(navigator.userAgent).getResult(), null, 2)

    const testNotif = () => {
        // Function to schedule local notification
        function scheduleLocalNotification(title, options, delay) {
            if ('serviceWorker' in navigator && 'Notification' in window) {
                navigator.serviceWorker.ready.then(registration => {
                    registration.active.postMessage({
                        type: 'scheduleNotification',
                        title: title,
                        options: options,
                        delay: delay
                    });
                }).catch(error => {
                    console.error('Error posting message to service worker:', error);
                });
            } else {
                console.error('Service worker or Notification API not supported');
            }
        }

        // Example usage
        scheduleLocalNotification('Reminder', {
            body: 'Test notif!',
            badge: '/favicon.png',
            image: '/favicon.png',
            icon: '/favicon.png',
            data : { test: "ok"},
            actions: [
                {
                    action: 'view',
                    title: 'View',
                    icon: '/view-icon.png'
                },
                {
                    action: 'dismiss',
                    title: 'Dismiss',
                    icon: '/dismiss-icon.png'
                },
            ],
        }, 10) 
    }

    return (
        <>
            <div onClick={testNotif} style={{margin:"2vw", backgroundColor: "blue", color: "white", padding: "2vw", textAlign: "center", cursor: "pointer"}}>Test Notification</div>
            <div className="user-agent-root">{info}</div>
        </>
    )
}

export default UserAgent