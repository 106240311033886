import { useRef, useState, useCallback } from "react"
import Header from '../../components/custom/header';
import "./index.css"
import ImageCropper from "./cropper";
import { useGlobal } from '../../context/global'

import Icon from "../../components/custom/icon";
import PageLoader from "../../components/info/pageloader";
import { useNavigate } from "react-router-dom";

const EditUserPhoto = () => {

    const navigate = useNavigate()
    const { auth, api, deviceInfo } = useGlobal()

    const minPhotoSize = 512

    const { user } = auth
    var [image, setImage] = useState(null)
    var [showBlockLoader, setShowBlockLoader] = useState(false)
    var [loaderText, setLoaderText] = useState("Opening image...")
    var imgip = useRef()

    const initImageSelect = ()=>{
        imgip.current.click()
    }

    const onUserPictureSelect = (e) => {
        if(!e.target.files){
            return
        }
        setShowBlockLoader(true)
        const reader = new FileReader()
        reader.onload = (e) => {
            var img = new Image()
            img.onload = ()=>{
                var min = img.naturalHeight>=img.naturalWidth?img.naturalWidth:img.naturalHeight
                if(min>=minPhotoSize){
                    setImage(e.target.result)
                }else{
                    alert(`This image is small in size. Please select an image that is atleast 800 pixels in width and height.`)
                }
                setShowBlockLoader(false)
            }
            img.src = e.target.result
        }
        reader.readAsDataURL(e.target.files[0])
    }

    const onComplete = useCallback(cropValue=>{

        setShowBlockLoader(true)
        setLoaderText("Cropping image...")

        const canvas = document.createElement('canvas')
        canvas.width = cropValue.width
        canvas.height = cropValue.height
        const ctx = canvas.getContext('2d')

        var img = new Image()
        img.onload = ()=>{
            ctx.drawImage(img, -cropValue.x, -cropValue.y)

            const scaledCanvas = document.createElement('canvas')
            scaledCanvas.width = minPhotoSize
            scaledCanvas.height = minPhotoSize
            const scaledCtx = scaledCanvas.getContext('2d')
            scaledCtx.drawImage(canvas, 0, 0, minPhotoSize, minPhotoSize)

            const imageDataURL = scaledCanvas.toDataURL('image/jpeg', 0.5)
            const filename = `${user.id}.jpeg`

            setLoaderText("Uploading image...")
            api.call('/set-user-photo', { imageDataURL, filename, id: user.id }).then(()=>{
                setLoaderText("Updating...")
                const sw = navigator.serviceWorker
                if (sw) {
                    sw.controller.postMessage({ type: 'clearCache', key: user.id })
                }
                setTimeout(()=>{
                    navigate(-1)
                    setShowBlockLoader(false)
                }, 5000)
            }).catch(err=>{
                console.log(err)
            })
            
            setImage(null)
        }
        img.src = image
    }, [image])

    const onAbort = ()=>{
        setImage(null)
    }

    return(
        <div className="edit-profile-root">
            {!image && <Header title={"Change User Photo"}/>}
            {showBlockLoader?<PageLoader block text={loaderText}/>:null}
            <input ref={imgip} id="image-picker" type="file" accept="image/*" onChange={onUserPictureSelect}/>

            {image?
                <ImageCropper src={image} minSize={minPhotoSize} onComplete={onComplete} onAbort={onAbort}/>
                :
                <div className="editup-img-sel-cont">
                    
                        <div className="editup-img-sel" onClick={initImageSelect}>
                            <div style={{marginBottom: "1vw"}}><b>{"Tap here to"}</b></div>
                            <div className="editup-flex">
                                <Icon className="editup-img-sel-icon" name="image" color={"black"}/>
                                <div>{"Select image from gallery"}</div>
                            </div>
                            {deviceInfo.isMobileDevice?<>
                                <div className="editup-or">{"OR"}</div>
                                <div className="editup-flex">
                                    <Icon className="editup-img-sel-icon" name="photo-camera" color={"black"}/>
                                    <div>{"Open camera"}</div>
                                </div>
                            </>:null}
                        </div>
                </div>
            }

        </div>
    )
}

export default EditUserPhoto 