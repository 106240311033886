import './App.css'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Login from './pages/login'
import Home from './pages/homepage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import EditUserPhoto from './pages/edit-up'
import Users from './pages/users'
import Install from './pages/install'
import { GlobalProvider } from './context/global'
import UserAgent from './pages/test/user-agent'
import Register from './pages/register'
import Applicants from './pages/applicants'
import { VolunteerProvider } from './context/volunteer'
import Volunteers from './pages/volunteers'

function App({ storeData, apiClient }) {

  return (
    <div className="App">
      <>
        <GlobalProvider storeData={storeData} apiClient={apiClient}>
          <Router>
            <Routes>
              <Route path="/login" exact element={<Login />}></Route>
              <Route path="/edit-up" exact element={<EditUserPhoto />}></Route>
              <Route path="/home" exact element={<Home />}></Route>
              <Route path="/users" exact element={<Users />}></Route>
              <Route path="/register" exact element={<Register />}></Route>
              <Route path="/applicants" exact element={<VolunteerProvider><Applicants /></VolunteerProvider>}></Route>
              <Route path="/volunteers" exact element={<VolunteerProvider><Volunteers /></VolunteerProvider>}></Route>
              <Route path="/test" exact element={<UserAgent />}></Route>
              <Route path="/" exact element={<Install />}></Route>

              <Route path="/test/user-agent" exact element={<UserAgent />}></Route>
            </Routes>
          </Router>
        </GlobalProvider>
      </>
      <ToastContainer />
    </div>
  )
}

export default App
