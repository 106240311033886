import "./index.css"
import Header from "../../components/custom/header"
import Volunteer from "./volunteer"
import { reversePayload } from "../../data/volunteer"
import { useVolunteer } from "../../context/volunteer"
import Tab from "../../components/holders/tab"
import { useGlobal } from "../../context/global"
import PageLoader from "../../components/info/pageloader"
import ListView from "../../components/holders/listView"

const Volunteers = () => {
    const { volunteers } = useVolunteer()
    const { auth } = useGlobal()

    const listMap = volunteer =>
        <Volunteer
            disabled={
                auth.user.roleID === 'ops-admin'
                    ? ['preacher-pending', 'admin-pending'].includes(volunteer.approval)
                    : auth.user.roleID === 'preacher'
                        ? ['ops-pending', 'admin-pending'].includes(volunteer.approval)
                        : false
            }
            key={volunteer.id}
            volunteer={reversePayload(volunteer)}
        />

    const sortConfig = [
        {
            name: "Name (A-Z)",
            key: 'name',
            asc: true
        },
        {
            name: "Name (Z-A)",
            key: 'name',
            asc: false
        },
        {
            name: "Latest",
            key: 'date',
            asc: false
        },
        {
            name: "Earliest",
            key: 'date',
            asc: true
        },
        {
            name: "Preacher (A-Z)",
            key: 'preacher',
            asc: true
        },
        {
            name: "Preacher (Z-A)",
            key: 'preacher',
            asc: false
        },
        {
            name: "Initiation Name (A-Z)",
            key: 'initiationName',
            asc: true
        },
        {
            name: "Initiation Name (Z-A)",
            key: 'initiationName',
            asc: false
        },
        {
            name: "Gender (M-F)",
            key: 'gender',
            asc: false
        },
        {
            name: "Gender (F-M)",
            key: 'gender',
            asc: true
        }
    ]

    return (
        <div className="volc-root">
            <Header title={'Volunteers'} />
            {volunteers ? (
                <div className="volc-main">
                    {volunteers && (
                        <ListView
                            list={volunteers[3]}
                            sortConfig={sortConfig}
                            map={listMap}
                        />
                    )}
                </div>
            ) : (
                <PageLoader text={'Loading volunteers list...'} />
            )}
        </div>
    )
}

export default Volunteers
