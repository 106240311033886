const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const phoneRegex = /^\d{10}$/

const coerceToArray = e => Array.isArray(e)?e:[e]

const filterAccess = (a, u) => a.filter(b => (b.exact || Array.isArray(b.access))?coerceToArray(b.access).indexOf(u.roleIndex)!=-1:u.roleIndex<=(b.access||99999))

const _ = {
    emailRegex,
    phoneRegex,
    coerceToArray,
    filterAccess
}

export default _