import "./index.css"
import DP from "../../components/custom/dp"
import { useCallback, useEffect, useState } from "react" 
import _ from "../../_"
import Form from "../../components/custom/form"
import { useGlobal } from "../../context/global"
import PageLoader from "../../components/info/pageloader"
import { toast } from 'react-toastify'
import Icon from "../../components/custom/icon"
import { useCropLoader, CropLoader } from "../../components/custom/croploader"
import moment from "moment"
import { getConfig, getPayload } from "../../data/volunteer"

const Register = () => {

    const { api } = useGlobal()
    var [ formConfig, setFormConfig ] = useState(getConfig([]))
    const minPhotoSize = 500
    const imageKey = 'volimage'
    const { select, crop, upload, setSelected, selected, cropped } = useCropLoader(minPhotoSize, localStorage.getItem(imageKey))

    var [ loaderText, setLoaderText ] = useState()

    useEffect(()=>{
        api.call('/get-preachers').then(res=>{
            setFormConfig(getConfig(res.data))
        }).catch(err=>{
            console.log(err)
            toast.error(`${err}`)
        })
    }, [])

    useEffect(()=>{
        cropped && localStorage.setItem(imageKey, cropped)
    }, [cropped])

    const submitForm = useCallback((formData, _, resetForm)=>{
        if(!cropped){
            toast.error('Upload your photo before submitting the form.')
            return
        }

        if(formData && cropped){

            setLoaderText('Submitting form...')

            var payload = {
                ...formData,
                approval: 'ops-pending',
                date: moment().format('YYYY-MM-DD')
            }

            api.call('/add-volunteer', {data: getPayload(payload, formData)}).then(res=>{
                setLoaderText('Uploading photo...')
                upload('/set-user-photo', `${res.data.id}.jpeg`).then(()=>{
                    setLoaderText(`Submission complete!`)
                }).catch(e=>{
                    setLoaderText(`Could not upload the photo: ${e}`)
                    console.log(`${e}`)
                }).finally(()=>{
                    alert('Your application form is succesfully submitted for review! You will now be taken to login page. Click OK.')
                    resetForm()
                    localStorage.clear(imageKey)
                    window.open('/login', '_self')
                })
            }).catch((e)=>{
                toast.error(`Could not submit the form: ${e}`)
                setLoaderText('')
                console.log(`${e}`)
            })
        }
    }, [cropped,, formConfig])

    const onAbort = () => setSelected(null)

    const instructions = [
        'Please upload a photo in which your face is clearly visible. A passport photo used for documents is a typical example.',
        'Do not upload photos in which you are wearing things that cover your face like helment, face mask, sun glass etc.',
        'This is not a social media display picture. This photo is meant for identification. In future, we may use this photo to print in Volunteer Badge. So please upload a proper photo.'
    ]

    return (
        <div className="reg-main">

            {loaderText && <PageLoader block text={loaderText} black/>}

            {selected && <CropLoader src={selected} minSize={minPhotoSize} onComplete={crop} onAbort={onAbort}/>}

            <div className="reg-header">
                <DP className="reg-logo" url="/favicon.png" size={15}/>
                <div className="reg-head-text">
                    <div className="reg-head-title">SevaBase Application Form</div>
                    <div className="reg-head-desc">ISKCON Mysore Volunteering</div>
                </div>
            </div>

            <div className="reg-dp-main">
                {cropped ? <DP url={cropped} size={40} badge={
                    {
                        size: 10,
                        color: 'var(--bar)',                        
                        icon: {
                            name: 'edit',
                            color: 'white',
                            onClick: select
                        }
                    }
                }/>:<div className="reg-dp-cont">
                        <div className="reg-dp-inst">
                            <div className="reg-inst-head">Instructions (Do not skip, please read completely):</div>
                            <div>
                                {
                                    instructions.map(inst => <div className="reg-in">{`• ${inst}`}</div>)
                                }
                            </div>
                        </div>
                        <div className="reg-dp-circle" onClick={select}>
                            <Icon  name="add-user-photo" size={cropped?[30, 20, 20]:[40, 20, 20]} stroke="#fff" color="none" thickness="1.5" className="reg-dp-plus"/>
                        </div>
                        <div className="reg-dp-desc">Click on the circle to upload your photo</div>
                        <a className="reg-over" href="/login" target="_self">If you have already submitted this form, click here!</a>
                    </div>
                }
        </div>

            {formConfig?
                <div className="reg-form-cont">
                    {cropped && <Form
                        name='registration'
                        config={formConfig}
                        onSubmit={submitForm}
                        user='user'
                        persist
                        paginate/>}
                </div>:
                cropped && <PageLoader text="Loading data..."/>
            }

        </div>
    )
}

export default Register