import DP from "../../../../components/custom/dp"
import Icon from "../../../../components/custom/icon"
import "./index.css"
import { getDescription } from "../../../../data/volunteer"
import Form from "../../../../components/custom/form"
import { useVolunteer } from "../../../../context/volunteer"

const VolunteerBio = ({volunteer, onEditBio, pass, del, viewOnly}) => {

    const { formConfig } = useVolunteer()

    return (
        <div className="volbio-root">
            <div className="volbio-head">
                <DP user={volunteer} size={40} enlarge/>
                
                <div className="volbio-head-name-cont">
                    <div className="volbio-headname">{volunteer.name}</div>
                    <div className="volbio-desc">{getDescription(volunteer)}</div>
                </div>

                <div className="volbio-action-buttons">
                    <a href={`tel:+91${volunteer.phone}`} target="_blank"><Icon name='call' size={6} color='green'/></a>
                    <a href={`sms:+91${volunteer.phone}`} target="_blank"><Icon name='sms' size={6} color='green'/></a>
                    <a href={`https://wa.me/91${volunteer.whatsapp || volunteer.phone}`} target="_blank"><Icon name='whatsapp' size={6} color='green'/></a>
                    {!viewOnly && <Icon name='edit' size={6} color='green' onClick={onEditBio}/>}
                    {!viewOnly && <Icon name='trash' size={6} color='red' onClick={del}/>}
                </div>
            </div>

            <div className="volbio-view-only-form">
                <Form
                    name='volunteers'
                    config={formConfig}
                    user='editor'
                    def={volunteer}
                    viewOnly
                    skip={['name', 'hasWhatsApp']}/>
            </div>

            <div className="volbio-pass" onClick={pass || (()=>{})}><Icon name='tick' color='#fff'/></div>
        </div>
    )
}

export default VolunteerBio