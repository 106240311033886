import "./index.css"
import Swipe from "../../../components/holders/swipe"
import DP from "../../../components/custom/dp"
import Icon from "../../../components/custom/icon"
import { useState } from "react"
import ContactBubble from "../../../components/holders/bubble/contact"
import ContextMenu from "../../../components/holders/contextmenu"
import Form from "../../../components/custom/form"
import VolunteerBio from "./bio"
import Modal from "../../../components/holders/modal"
import VolunteerEdit from "./edit"
import { useVolunteer } from "../../../context/volunteer"
import { toast } from "react-toastify"
import { useGlobal } from "../../../context/global"
import _ from "../../../_"

const Volunteer = ({volunteer, disabled}) => {

    const { auth, pageLoader } = useGlobal()

    const { setVolunteers, updateApproval, deleteVolunteer, formConfig, updateVolunteer } = useVolunteer()

    var [ showVolunteerCall, setShowVolunteerCall ] = useState(false)
    var [ showContextMenu, setShowContextMenu ] = useState(false)
    var [ showModal, setShowModal ] = useState(false)
    var [ showPreacherModal, setShowPreacherModal ] = useState(false)
    var [ showBio, setShowBio ] = useState(true)

    const onCall = () => {
        setShowVolunteerCall(true)
    }

    const closeCall = () => {
        setShowVolunteerCall(false)
    }

    const handlePreacherChange = (_, change)=>{
        pageLoader.show('Processing...')
        updateVolunteer(volunteer, change).then(res=>{
            setShowPreacherModal(false)
            setVolunteers(res.data)
        }).catch(e=>{
            toast.error(`Could not update preacher: ${e}`)
        }).finally(()=>{ pageLoader.hide() })
    }

    const pass = () => {
        if(window.confirm(`Are you sure you want to pass ${volunteer.name}?`)){
            pageLoader.show('Processing...')
            updateApproval(volunteer, 'preacher-pending').then(res=>{
                setShowModal(false)
                setVolunteers(res.data)
            }).catch(e => {
                toast.error(`Could not update approval: ${e}`)
            }).finally(()=>{ pageLoader.hide() })
        }
    }

    const preacherApprove = () => {
        if(window.confirm(`Are you sure you want to approve ${volunteer.name}? After your approval this entry will be sent for review by the admin.`)){
            pageLoader.show('Processing...')
            updateApproval(volunteer, 'admin-pending').then(res=>{
                setVolunteers(res.data)
            }).catch(e => {
                toast.error(`Could not update approval: ${e}`)
            }).finally(()=>{ pageLoader.hide() })
        }
    }

    const handleSendBackDataCheck = () => {
        if(window.confirm(`Are you sure you want to send back ${volunteer.name.split(' ')[0]}'s entry for data check?`)){
            pageLoader.show('Processing...')
            updateApproval(volunteer, 'ops-pending').then(res=>{
                setVolunteers(res.data)
            }).catch(e => {
                toast.error(`Could not send back for data check: ${e}`)
            }).finally(()=>{ pageLoader.hide() })
        }
    }

    const adminApprove = () => {
        if(window.confirm(`Are you sure you want to approve?`)){
            pageLoader.show('Processing...')
            updateApproval(volunteer, 'approved').then(res=>{
                setVolunteers(res.data)
            }).catch(e => {
                toast.error(`Could not approve: ${e}`)
            }).finally(()=>{ pageLoader.hide() })
        }
    }

    const handleSendBackScreening = () => {
        if(window.confirm(`Are you sure you want to send back ${volunteer.name.split(' ')[0]}'s entry for screening?`)){
            pageLoader.show('Processing...')
            updateApproval(volunteer, 'preacher-pending').then(res=>{
                setVolunteers(res.data)
            }).catch(e => {
                toast.error(`Could not send back for screening: ${e}`)
            }).finally(()=>{ pageLoader.hide() })
        }
    }

    const del = () => {
        if(window.confirm(`Are you sure you want to delete ${volunteer.name}?`)){
            pageLoader.show('Processing...')
            deleteVolunteer(volunteer).then(res=>{
                setShowModal(false)
                setVolunteers(res.data)
            }).catch(e => {
                toast.error(`Could not delete volunteer: ${e}`)
            }).finally(()=>{ pageLoader.hide() })
        }
    }

    const contextMenuConfig = {
        "ops-pending": [
            {
                name: 'Pass',
                onClick: pass,
                access: [0, 1, 2]
            },
            {
                name: 'Delete',
                onClick: del,
                className: 'vol-del',
                access: [0, 1, 2]
            }
        ],
        "preacher-pending": [
            {
                name: "Approve",
                onClick: preacherApprove,
                access: [1, 3, 0]
            },
            {
                name: 'Change Preacher',
                onClick: ()=>{ setShowPreacherModal(true) },
                access: [0, 1, 3]
            },
            {
                name: 'Send back for data check',
                onClick: handleSendBackDataCheck,
                access: [0, 1, 3]
            },
            {
                name: 'Undo Pass',
                onClick: handleSendBackDataCheck,
                access: [2]
            }
        ],
        "admin-pending": [
            {
                name: "Approve",
                onClick: adminApprove,
                access: [1, 0]
            },
            {
                name: 'Send back for data check',
                onClick: handleSendBackDataCheck,
                access: [0, 1]
            },
            {
                name: 'Send back for screening',
                onClick: handleSendBackScreening,
                access: [0, 1]
            },
        ],
        "approved": []
    }

    return (
        <div className="vol-main" key={volunteer.id}>

            {showModal &&
                <Modal title={volunteer.name} close={()=>{setShowModal(false)}}>
                    {showBio?
                        <VolunteerBio
                            volunteer={volunteer}
                            onEditBio={()=>{setShowBio(false)}}
                            pass={volunteer.approval=='ops-pending'?pass:volunteer.approval=='preacher-pending'?preacherApprove:adminApprove}
                            del={del}
                            viewOnly={auth.user.roleID=='preacher'}
                        />
                        :
                        <VolunteerEdit volunteer={volunteer} onBack={()=>{setShowBio(true)}}/>
                    }
                </Modal>
            }

            {showPreacherModal &&
                <Modal title={`Change Preacher for ${volunteer.name.split(' ')[0]}`} close={()=>{setShowPreacherModal(false)}}>
                    {
                        <div className="vol-chpr">
                            <Form
                                name='volunteer-edit'
                                config={formConfig}
                                user='editor'
                                def={volunteer}
                                updateMode
                                onSubmit={handlePreacherChange}
                                disableReset
                                noheaders
                                nomand
                                only={['preacher']}/>
                        </div>
                    }
                </Modal>
            }

            {showVolunteerCall && <ContactBubble name={volunteer.name} phone={volunteer.phone} whatsApp={volunteer.whatsApp} close={closeCall}/>}

            <Swipe disabled={disabled} right={{
                maxDrag: 20,
                onSwipe: onCall
            }}>
                <div className="vol-root">
                    <DP user={volunteer} size={12.5} enlarge={true}/>
                    <div className="vol-body-holder">

                        <div onClick={()=>{!disabled && setShowModal(true)}} style={{width: '100%'}}>
                            <div className="vol-body">
                                <div className="vol-details">
                                    <div className="vol-name">{volunteer.name}</div>
                                    <div className="vol-preacher">{volunteer.preacher || 'No Preacher'}</div>
                                </div>
                            </div>
                        </div>

                        <div style={{ position: 'relative' }}>
                            <div>
                                <Icon name='more-horiz' color='#888' onClick={()=>{setShowContextMenu(true)}}/>
                                {showContextMenu && <ContextMenu close={()=>{setShowContextMenu(false)}} orientation={'left'} config={contextMenuConfig[volunteer.approval]}/>}
                            </div>
                        </div>

                    </div>

                </div>
            </Swipe>
        </div>
    )
}

export default  Volunteer