import Spinner from "../spinner"
import "./index.css"

const PageLoader = ({text, size, block, black})=>{
    return (
        <div className={`pageload-${block?'block':'main'} ${black?'black':''}`}>
            <Spinner text={text} size={size} black={black}/>
        </div>
    )
}

export default PageLoader