import { useState } from "react"
import Form from "../../../../components/custom/form"
import Icon from "../../../../components/custom/icon"
import "./index.css"
import PageLoader from "../../../../components/info/pageloader"
import { toast } from "react-toastify"
import { useVolunteer } from "../../../../context/volunteer"

const VolunteerEdit = ({volunteer, onBack, only, isApplicant}) => {

    var { setVolunteers, formConfig, updateVolunteer } = useVolunteer()

    var [ saving, setSaving ] = useState(false)

    const handleSubmit = (_, changes) => {
        setSaving(true)
        updateVolunteer(volunteer, changes)
        .then(res => {
            setVolunteers(res.data)
            onBack()
        }).catch(e => {
            toast.error(`Could not save changes: ${e}!`)
        }).finally(()=>{
            setSaving(false)
        })
    }

    const handleBack = () => {
        if(window.confirm('Changes will be lost, if any. Are you sure you want to exit?')){
            onBack()
        }
    }

    return (
        <div className="voledit-root">

            {saving && <PageLoader block text={'Saving changes...'}/>}

            <div style={{width: '90%'}}>
                <Icon name='arrow-circle-left' color='#555' onClick={handleBack}/>
            </div>
            <Form
                name='volunteer-edit'
                config={formConfig}
                user='editor'
                def={volunteer}
                updateMode
                onSubmit={handleSubmit}
                disableReset
                noheaders
                floatingSubmit
                only={only}/>
        </div>
    )
}

export default VolunteerEdit