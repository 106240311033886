import "./index.css"
import Icon from "../../icon"
import { useNavigate } from "react-router-dom"
import { useGlobal } from '../../../../context/global'
import Glass from "../../glass"


const AppMenu = ({open, close})=>{

    const { auth } = useGlobal()
    const { user } = auth

    const navigate = useNavigate()

    const menus = [
        {
            name: "Home",
            link: "/home",
            icon: "home",
            access: 9
        },
        {
            name: "Users",
            link: "/users",
            icon: "group",
            access: 0
        },
        {
            name: 'Applicants',
            link: "/applicants",
            icon: 'description',
            access: 3
        },
        {
            name: 'Volunteers',
            link: "/volunteers",
            icon: 'diversity3',
            access: 3
        }
    ]

    return (
        <div>
            <div className={`appmenu-slider ${open?"open":""}`}>
                <div className="appmenu-label">MENU</div>
                <div className="appmenu-items">
                    {
                        menus.filter(m=>{
                            return m.exact?m.access==user.roleIndex: m.access >= user.roleIndex
                        }).map(m=>{
                            const handleMenuClick = ()=>{
                                navigate(m.link)
                            }
                            return(
                                <div className={`appmenu-item ${window.location.pathname==m.link?"selected":""}`} onClick={handleMenuClick}>
                                    <Icon name={m.icon} color="#fff" size={[5, 2.25, 1.75]}/>
                                    <div className="appmenu-item-name">{m.name}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {open?<Glass onClick={close}/>:null}
        </div>
    )
}

export default AppMenu