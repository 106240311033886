import indexed from "../indexed"

const version = "1.5"

const codec = (data) => {
  if (!data) {
    return data
  }

  const type = 'string'
  let result = ''
  for (let i = 0; i < data.length; i++) {
    result += String.fromCharCode(data.charCodeAt(i) ^ type.charCodeAt(i % type.length))
  }
  return result
}

const get = async () => {
  return new Promise((resolve, reject) => {
    indexed.get("store", "store", "save").then(result => {
      try {
        resolve(JSON.parse(codec(result)))
      } catch (error) {
        reject(error)
      }
    }).catch(error => {
      reject(error)
    })
  })
}

const set = async (storeData) => {
  return indexed.set("store", "store", "save", codec(JSON.stringify({ version, ...storeData })))
}

const clear = async () => {
  return indexed.clear("store", "store", "save")
}

const reset = async () => {
  await clear("store", "store", "save")
  return window.location.pathname == '/login' ? null : `/login?redirect=${window.location.pathname}`
}

const auth = async () => {

  var authObj = {}

  const openRoutes = [
    '/', '/register'
  ]

  if (openRoutes.indexOf(window.location.pathname) != -1) {
    return authObj
  }

  try {
    const store = await get()
    authObj.storeData = store

    if (!store || store.version != version) {
      authObj.redirectionURL = await reset()
      return authObj
    }

    if (window.location.pathname == '/login') {
      if (store) {
        authObj.redirectionURL = new URLSearchParams(window.location.search).get('redirect') || '/home'
      }
    } else {
      if (!store) {
        authObj.redirectionURL = `/login?redirect=${window.location.pathname}`
      }
    }

    return authObj
  } catch (err){
    authObj.redirectionURL = await reset()
    return authObj
  }

}

const store = {
  auth, get, set, clear
}

export default store