import { useState } from 'react'
import './index.css'
import DP from '../dp'
import Icon from '../icon'
import UserMenu from './userMenu'
import AppMenu from './appMenu'
import { useGlobal } from '../../../context/global'
import ContextMenu from '../../holders/contextmenu'

function Header({className, title}) {

  const { auth } = useGlobal()
  const { user } = auth

  var [appMenuOpen, setAppMenuOpen] = useState(false)
  var [userMenuOpen, setUserMenuOpen] = useState(false)
  var [showContextMenu, setShowContextMenu] = useState(false)

  const toggleAppMenu = ()=>{
    setAppMenuOpen(!appMenuOpen)
  }

  const showUserMenu = ()=>{
    setUserMenuOpen(true)
  }

  const hideUserMenu = ()=>{
    setUserMenuOpen(false)
  }

  const closeAppMenu = ()=>{
    setAppMenuOpen(false)
  }

  const closeUserMenu = ()=>{
    setUserMenuOpen(false)
  }

  const getBadge = (u)=>{

    var badge = {
      color: 'var(--surface)',
      size: 3.5,
      icon: {
        color: 'white'
      }
    }

    switch(u.roleID){
      case 'app-admin':
        badge.icon.name = 'settings'
        return badge
      case 'folk-guide':
        badge.icon.name = 'supervisor-account'
        return badge
      case 'buddy-coord':
        badge.icon.name = 'group'
        return badge
      default:
        return null
    }
  }

  const contextMenuConfig = [
    {
      name: 'Refresh',
      onClick: ()=>{ window.location.reload() }
    }
  ]

  return (
    user?
    <div className="header-holder">

      <div className={`header ${className || ""}`}>
          <div className='header-left'>
            <Icon
              name={`menu`}
              color="white"
              onClick={toggleAppMenu}
              className={"header-icon"}
            />
            <div className='header-title'>{title}</div>
          </div>

          {!title && <div className='header-text'>
            <img className='header-logo' src='/logo.png'/>
          </div>}
          
          <div className='header-right'>
            <DP
              onClick={showUserMenu}
              user={user}
              className={`header-dp`}
              size={8}
              badge={getBadge(user)}/>
            <div>
              <Icon name="more-vert" color="white" onClick={()=>{setShowContextMenu(true)}}/>
              {showContextMenu && 
                <ContextMenu
                  orientation="left"
                  close={()=>{setShowContextMenu(false)}}
                  config={contextMenuConfig}>
                </ContextMenu>
              }
            </div>
          </div>

      </div>

      {userMenuOpen && <UserMenu open={userMenuOpen} close={closeUserMenu}/>}
      <AppMenu open={appMenuOpen} close={closeAppMenu}/>

    </div>:null
  );
}

export default Header;
