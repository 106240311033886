import React, { createContext, useContext, useEffect, useState } from 'react'
import { useGlobal } from '../global'
import _ from '../../_'
import { getConfig, getPayload } from "../../data/volunteer"
import { toast } from 'react-toastify'


const VolunteerContext = createContext()

export const VolunteerProvider = ({ children }) => {

  const { api } = useGlobal()
  var [volunteers, setVolunteers] = useState()
  var [formConfig, setFormConfig] = useState()

  useEffect(() => {
    api.call('/get-volunteers').then(res => {
      setVolunteers(res.data)
      api.call('/get-preachers').then(res => {
        setFormConfig(getConfig(res.data))
      }).catch(e => {
        toast.error(`Could not get preachers data: ${e}`)
      })
    }).catch(e => {
      toast.error(`Could not get volunteers data: ${e}`)
    })
  }, [])

  const updateApproval = (volunteer, approval) => api.call('/update-volunteer-approval', { volunteers: _.coerceToArray(volunteer).map(({id}) => { return { id, approval }})} )
  const deleteVolunteer = volunteer => api.call('/delete-volunteer', { volunteers: _.coerceToArray(volunteer) })
  const updateVolunteer = (volunteer, changes) => api.call('/update-volunteer', { id: volunteer.id, changes: getPayload(changes, changes, true) })

  return (
    <VolunteerContext.Provider value={{ volunteers, formConfig, setVolunteers, deleteVolunteer, updateVolunteer, updateApproval }}>
      {children}
    </VolunteerContext.Provider>
  )
}

export const useVolunteer = () => {
  return useContext(VolunteerContext)
}
