import Icon from "../../custom/icon"
import Glass from "../../custom/glass"
import "./index.css"

const Modal = ({title, close, children})=>{
  return (
    <div className="modal-root">
      <Glass dark opacity={0.4}/>
      <div className="modal-box">

          <div className="modal-header">
              <div className="modal-head">{title}</div>
              <Icon name='close' color='white' size={4} onClick={close}/>
          </div>

          <div className="modal-children">
            {children}
          </div>

      </div>
  </div>
  )
}

export default Modal