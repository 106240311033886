import "./index.css"
import Icon from '../../../icon';
import { useEffect, useState } from "react";

const Checkbox = props => {
    const { onEditComplete, onFocusChange, def, label } = props

    var [ value, setValue ] = useState(def)
    var [ focus, setFocus ] = useState()

    useEffect(() => {
        if(focus!==undefined){
            onFocusChange && onFocusChange(focus)
        }
    }, [focus])

    useEffect(()=>{
        value!==undefined && onEditComplete && onEditComplete(value)
    }, [value])

    const handleFocus = () => setFocus(true)
    const handleBlur = () => setFocus(false)
    const toggleCheckbox = () => setValue(v=>!v)

    return (
        <div className={`check-root`} tabIndex={0} onFocus={handleFocus} onBlur={handleBlur} onClick={toggleCheckbox}>
            <Icon name={value?"checkbox":'uncheckbox'} color={value?'#333':'#888'} size={4.5}/>
            <div className={`check-label`}>{label}</div>
        </div>
    )
}

export default Checkbox