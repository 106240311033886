import { useCallback, useState } from "react"
import "./index.css"

const Swipe = ({width, height, left, right, children, className, style, hide, disabled})=>{

    var [ touchPoint, setTouchPoint ] = useState(null)
    var [ dragWidth, setDragWidth ] = useState(0)

    const handleTouchStart = useCallback((e)=>{

        if(disabled){
          return
        }

        setTouchPoint({
          x: e.touches[0].clientX,
          y: e.touches[0].clientY
        })
    }, [])
  
    const handleTouchMove = useCallback((e)=>{
      if(touchPoint===null){
        return
      }
      const height = ((e.touches[0].clientY-touchPoint.y)/window.innerHeight)*100
      if(Math.abs(height)>10){
        setDragWidth(0)
        setTouchPoint(null)
        return
      }
      
      const width = ((e.touches[0].clientX-touchPoint.x)/window.innerWidth)*100
      if(!(width>=0 && !right || width<0 && !left)){
        var swipeParams
        if(width>=0){
            swipeParams = right || {}
        }else{
            swipeParams = left || {}
        }

        const {maxDrag, minDrag} = swipeParams

        if(maxDrag){
            if(Math.abs(width)>maxDrag){
                setDragWidth((width/Math.abs(width))*maxDrag)
            }else{
                setDragWidth(width)
            }
        }else if(minDrag){
            if(Math.abs(width)<minDrag){
                setDragWidth(0)
            }else{
                setDragWidth(width)
            }
        }else {
            setDragWidth(width)
        }
      }

    }, [touchPoint])
  
    const handleTouchEnd = useCallback(()=>{
      if(right && right.onSwipe){
        if(dragWidth>0 && right){
          if(right.maxDrag){
            if(dragWidth>=right.maxDrag){
              right.onSwipe()
            }
          }else{
            right.onSwipe()
          }
        }
      }
      
      if(left && left.onSwipe){
        if(dragWidth<0 && left){
          if(left.maxDrag){
            if(Math.abs(dragWidth)>=left.maxDrag){
              left.onSwipe()
            }
          }else{
            left.onSwipe()
          }
        }
      }

      setTouchPoint(null)
      setDragWidth(0)
    }, [dragWidth])
  
    const getRightSwipeWidth = useCallback(()=>{
      return `${(dragWidth>=0?Math.abs(dragWidth):0)+(right.padWidth||0)}vw`
    }, [dragWidth])

    const getLeftSwipeWidth = useCallback(()=>{
      return `${(dragWidth<0?Math.abs(dragWidth):0)+(left.padWidth||0)}vw`
    }, [dragWidth])

    width = width || "100vw"
    height = height || "auto"

    return (
        <div
            className={`swipe-root ${className||""}`}
            style={{ width, height, display: hide?"none":"" , ...{style} }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div className="swipe-left-pad" style={right?{width: getRightSwipeWidth(), backgroundColor: right.color || "#00000000"}:null}>{right && right.icon || null}</div>
            <div className="swipe-child">{children}</div>
            <div className="swipe-right-pad" style={left?{width: getLeftSwipeWidth(), backgroundColor: left.color || "#00000000"}:null}>{left && left.icon || null}</div>
        </div>
    )
}

export default Swipe