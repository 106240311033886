import "./index.css"
import Bubble from ".."
import Icon from "../../../custom/icon"

const ContactBubble = ({name, phone, whatsApp, close})=>{
    return (
        <Bubble dark onClose={close}>
          <div className='conble-actions' onClick={close}>

            <a className='conble-action' href={`tel:+91${phone}`} target='_blank'>
              <Icon className="conble-action-icon" name="call" color="green" size={[6, 2.5, 2.5]}/>
                {`Call ${name || ""}`.trim()}
            </a>

            <div className="conble-sep"/>

            <a className='conble-action' href={`https://wa.me/91${whatsApp || phone}`} target='_blank'>
              <Icon className="conble-action-icon" name="whatsapp" color="green" size={[6, 2.5, 2.5]}/>
                {`WhatsApp ${name || ""}`.trim()}
            </a>

          </div>  
        </Bubble>
    )
}

export default ContactBubble

