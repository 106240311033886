import React, { useState } from 'react'
import "./index.css"
import Header from '../../components/custom/header'
import { useNavigate } from "react-router-dom"
import { useGlobal } from '../../context/global'
import ConstructionPage from './homes/construction'

function Home() {
  const navigate = useNavigate()
  const { auth } = useGlobal()
  const { user } = auth || {}

  const HomeContent = () => {
    switch (user.roleID) {
      case "volunteer":
        return <ConstructionPage />
      default:
        return <ConstructionPage />
    }
  }

  return (
    <div>
      <Header title={'SevaBase'} />
      <div className='home-main'>
        <HomeContent />
      </div>
    </div>
  )
}


export default Home
